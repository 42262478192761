import React from 'react'
import Layout from '../components/layout'

export default () => {
  
  return (
    <Layout title="Contact">
      <h1>
        Contact
      </h1>
      <ul>
        <li>
          <a href="mailto:stk@antisound.net">Press & Booking (Australia)</a>
        </li>
        <li>
          <a href="mailto:hello@denovali.com">Press & Booking (outside Australia)</a>
        </li>
        <li>
          <a href="https://terminalsoundsystem.bandcamp.com" target="_blank" rel="noopener noreferrer">Bandcamp</a>
        </li>
        <li>
          <a href="https://open.spotify.com/artist/2Lbda7fPIO7HgKFUo7wx4J" target="_blank" rel="noopener noreferrer">Spotify</a>
        </li>
        <li>
          <a href="https://facebook.com/terminalsoundsystem" target="_blank" rel="noopener noreferrer">Facebook</a>
        </li>
      </ul>
    </Layout>
  )
}
